import React from "react"
import Navbar from "../components/Navbar"
import Sidebar from '../components/Sidebar'
import Spacer from "../components/Spacer"
import Footer from "../components/Footer"
import Header from "../components/TitleElement"
import CenterText from '../components/CenterText.js'
import { Helmet } from 'react-helmet' 

export const PrivacyTemplate = ({
    navbar,
    sidebar,
    footer1,
    footer2,
    privacy
    }) => (
        <div>
            <Helmet>
                <meta name="robots" content="noindex" charSet="utf-8"/>
                <title>Datenschutz</title>
                <html lang="de"/>
            </Helmet>

            <Navbar data={navbar} topbar={sidebar}/>
            <Sidebar data={sidebar}/>
            <Spacer/>
            <Header>{privacy.title}</Header>
            <Spacer/>
            <CenterText>
            {
                privacy.TextSections.map((item, i) => (
                    <div key={i}>
                        <h3>{item.title}</h3>
                        <div dangerouslySetInnerHTML={{__html: item.text}}/>
                    </div>
                ))
            }
            </CenterText>
            <Spacer height="100" unit="px"/>
            <Footer data={footer1} data2={footer2} id="contact"/>
        </div>
)
