import React from "react"
import { graphql } from 'gatsby'
import { PrivacyTemplate } from '../templates/privacyTemplate.js'

/*

*/
export default function Privacy({data}) {
    const navbar = data.allDataJson.nodes[0].index.navbar
    const sidebar = data.allDataJson.nodes[0].index.general
    const footer1 = data.allDataJson.nodes[0].index.general
    const footer2 = data.allDataJson.nodes[0].index.footer
    const privacy = data.allDataJson.nodes[0].privacy
    return (
        <PrivacyTemplate
        navbar={navbar}
        sidebar={sidebar}
        footer1={footer1}
        footer2={footer2}
        privacy = {privacy}
        />

    )
}

export const query = graphql`
{
    allDataJson {
        nodes {
            index {
                navbar {
                    title
                    navigationButtons {
                    title
                    link
                    }
                }
                general {
                    title
                    opening {
                    details{
                        text
                    }
                    times {
                        days
                        time
                    }
                    title
                    }
                    phone {
                    detail
                    number
                    title
                    }
                    email {
                        title
                        adress
                    }
                    adress {
                    title
                    street
                    zipcode
                    }
                }
                footer {
                    slogan
                    copyright
                    endorsement
                }
            }
            privacy {
                title
                TextSections {
                    title
                    text
                }
            }
        }
    }
}

`